import React from "react"
import { Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Article from "../components/article"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionA, FlexBox, styles } from "../components/styles"
import Container from "../components/container"
import Grid from "../components/grid"
import TopImage from "../components/top-image"
import Fade from "react-reveal/Fade"

// const NavLink = props => {
//   if (!props.test) {
//     return <Link to={props.url}>{props.text}</Link>
//   } else {
//     return <span></span>
//   }
// }

const PaginationBox = styled(FlexBox)`
  position: relative;
  margin-top: 25px;

  span {
    font-size: 12px;
    line-height: 14px;
    color: ${styles.colorPrimary};
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .previousLink {
    a {
      padding-left: 25px;

      &::after {
        left: 0;
        border-left-color: transparent;
      }
    }
  }

  .nextLink {
    a {
      padding-right: 25px;

      &::after {
        right: 0;
        border-right-color: transparent;
      }
    }
  }

  .previousLink,
  .nextLink {
    a {
      position: relative;
      font-size: 12px;
      line-height: 14px;
      color: ${styles.colorPrimary};
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${styles.colorSecondary};
      }

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-width: 4px;
        border-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
      }
    }
  }
`

const ArticlesContainer = styled(Container)`
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1366px) {
    max-width: 1270px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const ArticleGrid = styled(Grid)`
  .react-reveal {
    &:nth-child(3n + 1) {
      animation-delay: 300ms !important;
    }

    &:nth-child(3n + 2) {
      animation-delay: 500ms !important;
    }

    &:nth-child(3n + 3) {
      animation-delay: 700ms !important;
    }
  }
`

const Blog = ({ data, pageContext }) => {
  // const { group, index, first, last, pageCount } = pageContext
  // const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString()
  // const nextUrl = (index + 1).toString()
  const pageBlogQuery = useStaticQuery(graphql`
    query pageBlogQuery {
      allWordpressPost {
        edges {
          node {
            date
            title
            slug
            categories {
              name
              slug
            }
            featured_media {
              title
            }
            acf {
              artykul
              zajawka
            }
          }
        }
      }
    }
  `)

  const allPosts = pageBlogQuery.allWordpressPost.edges

  return (
    <Layout>
      <SEO title="Aktualności" />
      <TopImage title={"Aktualności"} />
      <SectionA p={"67px 0"}>
        <ArticlesContainer>
          <ArticleGrid
            className={"articleGrid"}
            xs={"1fr"}
            lg={"50% 50%"}
            xl={"33.33% 33.33% 33.33%"}
            columnGap={"0"}
            rowGap={"0"}
          >
            {allPosts.map(({ node }) => (
              <Fade>
                <Article
                  date={node.date}
                  title={node.title}
                  short={node.acf.zajawka}
                  excerpt={node.acf.artykul}
                  slug={node.slug}
                  social={"facebook"}
                  categories={node.categories.map(category => category.name)}
                  categoriesSlug={node.categories.map(
                    category => category.slug
                  )}
                  image={
                    node.featured_media ? node.featured_media.title : "Header"
                  }
                />
              </Fade>
            ))}
          </ArticleGrid>
        </ArticlesContainer>
        {/* <Container>
              <PaginationBox justify={"space-between"}>
                <div className="previousLink">
                  <NavLink test={first} url={"blog/" + previousUrl} text="Poprzednia strona" />
                </div>
                <span>Liczba stron: {pageCount}</span>
                <div className="nextLink">
                  <NavLink test={last} url={"blog/" + nextUrl} text="Następna strona" />
                </div>
              </PaginationBox>
          </Container> */}
      </SectionA>
    </Layout>
  )
}

export default Blog

// export const categoryQuery = graphql`
//   query {
//     allWordpressPost {
//       edges {
//         node {
//           date
//           title
//           slug
//           categories {
//             name
//             slug
//           }
//           featured_media {
//             title
//           }
//           acf {
//             artykul
//           }
//         }
//       }
//     }
//   }
// `;
